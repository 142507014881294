import { loginClient } from "@components/solid/AuthContext";
import { SessionHTMLElement } from "@components/web/Session";

export class SessionAction extends SessionHTMLElement {
  private _root: ShadowRoot;

  get action() {
    return this.getAttribute(SessionAction.attr().action);
  }

  get target() {
    return this.getAttribute(SessionAction.attr().target);
  }

  static attr() {
    return {
      action: "action",
      target: "target",
    };
  }

  static observedAttributes = [
    SessionAction.attr().action,
    SessionAction.attr().target,
  ];

  constructor() {
    super();
    this._root = this.attachShadow({ mode: "open" });
  }

  async render() {
    this._root.innerHTML = `
            <slot part="action">
                <button>Logg på</button>
            </slot>
        `;
  }

  async handleClick(evt: Event): Promise<void> {
    const target = evt.target as HTMLButtonElement;
    if (this.getAttribute(SessionAction.attr().action) === "login") {
      return loginClient?.login();
    }

    if (this.getAttribute(SessionAction.attr().action) === "logout") {
      return loginClient?.logout();
    }

    if (this.getAttribute(SessionAction.attr().action) === "navigate") {
      if (this.getAttribute(SessionAction.attr().target) === "userpage") {
        return loginClient?.navigateTo({ target: "userpage" });
      }

      throw new Error(
        `Invalid navigation target: ${this.getAttribute(SessionAction.attr().target)}`,
      );
    }

    throw new Error(
      `Invalid action: ${this.getAttribute(SessionAction.attr().action)}`,
    );
  }

  async connectedCallback() {
    await this.sessionConnectedCallback();
    this._root
      .querySelector('[part="action"]')
      ?.addEventListener("click", this.handleClick.bind(this) as EventListener);
  }
}
