import { SessionHTMLElement } from "@components/web/Session";

export class SessionSwitch extends SessionHTMLElement {
  private _root: ShadowRoot;

  constructor() {
    super();
    this._root = this.attachShadow({ mode: "open" });
  }

  async render() {
    this._root.innerHTML = `
        <style>
          :host {
            display: contents;
          }
          :host::part(active) {
            display: ${this.isLoggedIn() ? "contents" : "none"};
          }
          :host::part(inactive) {
            display: ${this.isLoggedIn() ? "none" : "contents"};
          }
          ::slotted(*[name="active"]) {
            display: ${this.isLoggedIn() ? "contents" : "none"};
          }
          ::slotted(*[name="inactive"]) {
            display: ${this.isLoggedIn() ? "none" : "contents"};
          }
        </style>
        <slot part="active" name="active"></slot>
        <slot part="inactive" name="inactive"></slot>
      `;
  }
}
