export class ProgressMediaPlay extends HTMLElement {
  private _cir: number;
  private _stroke: number;
  private _radius: number;
  private _path: SVGPathElement | null;
  private _indicator: SVGCircleElement | null;
  private _iconCircle: SVGCircleElement | null;
  private _root: ShadowRoot;
  private _pausePath =
    "m 30 23 h -7 v 18 h 7 v -18 z m 11 0 h -7 v 18 h 7 v -18 z";
  private _playPath = "M27 22 h8 l5.5 10 l-5.5 10 h-8 l5.5 -10 l-5.5 -10z";

  static attributes() {
    return {
      progress: "data-progress",
      playing: "data-playing",
    };
  }

  get progress() {
    return Number(this.getAttribute(ProgressMediaPlay.attributes().progress));
  }

  get playing() {
    return this.getAttribute(ProgressMediaPlay.attributes().playing) === "true";
  }

  static calcDashStrokeOffset(circumference: number, percent: number) {
    return circumference - (percent / 100) * circumference;
  }

  constructor() {
    super();
    this._root = this.attachShadow({ mode: "open" });
    this._stroke = 3;
    this._radius = 30;
    this._cir = this._radius * 2 * Math.PI;
    this._root.innerHTML = `
        <style>
          :host {
            --nrk-progress-media-play-indicator-stroke-color: indigo;
            --nrk-progress-media-play-circle-fill-color: indigo;
            --nrk-progress-media-play-circle-stroke-color: transparent;
            --nrk-progress-media-play-symbol-fill-color: red;
          }

          :host::part(indicator) {
            stroke: var(--nrk-progress-media-play-indicator-stroke-color);
            transition: stroke-dashoffset 0.35s;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
          }
          
          :host::part(icon-circle) {
            fill: var(--nrk-progress-media-play-circle-fill-color);
            stroke: var(--nrk-progress-media-play-circle-stroke-color);
          }

          :host::part(symbol) {
            fill: var(--nrk-progress-media-play-symbol-fill-color);
          }
        </style>
        <svg
            viewbox="0 0 64 64"
        >
            <circle
                part="icon-circle"
                r="${this._radius - 6}"
                cx="32"
                cy="32"
            />
            <path part="symbol" d="${this._playPath}"></path>
            <circle
                part="indicator"
                style="stroke-dasharray: ${this._cir} ${this._cir}; stroke-dashoffset: ${this._cir}"
                stroke-width="${this._stroke}"
                fill="transparent"
                r="${this._radius}"
                cx="32"
                cy="32"
            />
        </svg>
    `;

    this._path = this._root.querySelector("path[part='symbol']");
    this._indicator = this._root.querySelector("circle[part='indicator']");
    this._iconCircle = this._root.querySelector("circle[part='icon-circle']");
  }

  setProgress(percent: number) {
    if (this._indicator) {
      this._indicator.style.strokeDashoffset =
        ProgressMediaPlay.calcDashStrokeOffset(this._cir, percent).toString();
    }
    if (this._iconCircle) {
      this._iconCircle.setAttribute(
        "r",
        (percent > 0 ? this._radius - 6 : this._radius).toString(),
      );
    }
  }

  setPlaying(playing: boolean) {
    if (this._path) {
      this._path.setAttribute("d", playing ? this._pausePath : this._playPath);
    }
  }

  attributeChangedCallback(name: string, _: string, newValue: string) {
    if (name === ProgressMediaPlay.attributes().progress) {
      this.setProgress(Number(newValue));
    }

    if (name === ProgressMediaPlay.attributes().playing) {
      this.setPlaying(newValue === "true");
    }
  }

  static get observedAttributes() {
    return [
      ProgressMediaPlay.attributes().progress,
      ProgressMediaPlay.attributes().playing,
    ];
  }
}
