import { SessionHTMLElement } from "@components/web/Session";

export class SessionUserAvatar extends SessionHTMLElement {
  constructor() {
    super();
  }

  async render() {
    this.innerHTML = `${this._sessionState?.user?.name?.charAt(0) ?? "?"}`;
  }
}
